.headerBg {
  background: #111111;
}

.logoBg {
  height: 100%;
  background: #0e0e0e;

  @media screen and (max-width: 1280px) {
    background: #111111;
  }
}

.drawer {
  background-color: #111111;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}
