.vestingTable__container {
  max-width: 100%;
  overflow-x: auto;
  margin-top: 24px;
}

.vestingTable {
  th {
    border-bottom: 1px solid #2e2e2e;
  }

  tr:first-child td {
    padding-top: 3em;
  }
}
