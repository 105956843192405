.layout {
  padding: 48px 202px 0;
  @media screen and (max-width: 1280px) {
    padding: 20px;
  }
}

.preContent {
  // position: absolute;
  width: 484px;
  height: 88px;
  justify-self: center;
  // left: 441px;
  // top: 235px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  /* or 138% */

  text-align: center;

  /* F9F9F9 */

  color: #f9f9f9;
}
/* Please Connect Your Wallet to view Stader token vesting */

.terrLink {
  height: 24px;
  left: 36.31%;
  right: 38.07%;
  top: 100px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  /* 8C8C8C */

  color: #8c8c8c;
}

.connector {
  align-items: "center";
  display: "flex";
  flex-direction: "column";
  height: "auto";
  justify-content: "center";
  margin-left: "auto";
  margin-right: "auto";
  padding: "20px 5px";
  cursor: "pointer";
}
